import http from "../http-common";
import store from '../store'

class SalaryDataService {
    
 

  update(data) {
    return http.put(`/salarybulk`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

 

 
}

export default new SalaryDataService();