<template>
  <TopNav />
  <SideBar />
   <main class="ml-12 pl-3">
  <div class="row mt-5 pt-5 ms-5">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <h5 class="text-center">Genel Gelir Gider Raporu</h5>
        <h6 class="text-center">{{period}} Dönemi</h6>
      </div>
    </div>
    <div class="row">
      
      <div class="col-md-10"></div>
      <div class="col-md-2"><button class="btn btn-info" @click="toExcel"><i class="bi bi-download"></i> Excele Aktar</button></div>
  </div>

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
    
              
            <tr>
              <th>İş Türü</th>
              <th>Gelir</th>
              <th>Gider</th>
             
              <th>Kar/Zarar</th>
              <th>Toplam Saat</th>
              <th>Adam/İş</th>
              <th>İlgilenen Kişi</th>
              
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(staff, index) in this.clientItems"
              :key="index" 
            >
            <td>{{staff.is_turu}}</td>
            <td>{{binl(staff.gelir)}}</td>
            <td>{{binl(staff.gider)}}</td>
            <td>{{binl(staff.kar_zarar)}}</td>
            <td>{{binl(staff.toplam_saat)}}</td>
            <td>{{binl(parseFloat(staff.toplam_saat/225).toFixed(2))}}</td>
            <td>{{staff.toplam_kisi}}</td>
            

              
            </tr>
          </tbody>
        </table>
      </div>
    

   
  
    </div>
  </div>
   </main>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import TableToExcel from "table-to-excel/table-to-excel.js"

import "jzip/jzip"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import $ from "jquery";
import { mapState } from "vuex";
import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";

export default {    
    name: "GeneralReport",
    components: {
      TopNav,
      SideBar},
    computed: mapState(["access_token", "api_url"]),
    mounted() {
   
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"gr/"+this.period, requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.clientItems = data;
       
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: -1,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            ordering: false
          });
        });
      });
  },
    data(){
        return{
            
            period: this.$route.params.period,
            
            clientName: null,
            clientItems: []
        }
    },
    methods: {
      toExcel(){
        var tableToExcel=new TableToExcel();
        tableToExcel.render("datatable")
      },
      binl(n) {
       return Number(Number(n).toFixed(2)).toLocaleString('tr-TR')
      }
    }
}
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>