<template>
  <div class="row">
    <div class="col-12">
      <h4 v-if="currentTask">{{ currentTask.name }} Bilgileri</h4>
      <div class="responsive-tabs">
        <ul class="nav nav-tabs" role="tablist">
           <li class="nav-item">
            <a
              id="tab-AA"
              href="#pane-AA"
              class="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-eye"> Görüntüle</i
            ></a>
          </li>
          
          <li class="nav-item">
            <a
              id="tab-BB"
              href="#pane-BB"
              class="nav-link "
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-pencil"> Düzenle</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-CC"
              href="#pane-CC"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-plus-circle"> Yeni</i
            ></a>
          </li>
          
        </ul>

        <div id="content" class="tab-content" role="tablist">
          <div
            id="pane-AA"
            class="card tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="tab-AA"
          >
            <div class="card-header" role="tab" id="heading-AA">
              <div class="card-body">
                <CallenderBox :currentTask="currentTask" />
              </div>
            </div>
          </div>
          <div
            id="pane-BB"
            class="card tab-pane fade show "
            role="tabpanel"
            aria-labelledby="tab-BB"
          >
            <div class="card-header" role="tab" id="heading-BB">
              <div class="card-body">
                <CallenderEdit :currentTask="currentTask" @edited="$emit('refreshSent')"/>
              </div>
            </div>
          </div>
          <div
            id="pane-CC"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-CC"
          >
            <div class="card-header" role="tab" id="heading-CC">
              <div class="card-body">
                <CallenderNew @edited="this.$emit('refreshSent')"/>
              </div>
            </div>
          </div>
         
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallenderBox from "./CallenderBox.vue";
import CallenderEdit from "./CallenderEdit.vue";
import CallenderNew from "./CallenderNew.vue";

export default {
  name: "CallenderList",
  props: ["currentTask"],
  emits:["refreshSent",],
  data() {
    return {
      
    };
  },
  
  

  components: {
    // ClientBox,
    CallenderEdit,
    CallenderNew,
    CallenderBox
  },
};
</script>

<style scoped>
.bi-dash-circle {
  color: crimson !important;
  size: 40px !important;
}
</style>