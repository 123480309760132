import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Clients from '../views/Clients.vue'
import Staff from '../views/Staff.vue'

import Project from '../views/Project.vue'
import ProjectNew from '../views/ProjectNew.vue'

import Income from '../views/Income.vue'
import Expense from '../views/Expense.vue'
import MyExpense from '../views/MyExpense.vue'
import MyTasks from '../views/MyTasks.vue'
import Gorevlerim from '../views/Gorevlerim.vue'
import Tasks from '../views/Tasks.vue'
import ArchivedTasks from '../views/ArchivedTasks'
import AskDelays from '../views/AskDelays.vue'
import AskDelayAdmin from '../views/AskDelayAdmin.vue'
import AllTask from '../views/AllTask.vue'
import Questionnaire from '../views/Questionnaire.vue'
import Raporlar from '../views/Raporlar.vue'
import Kisiler from '../views/Kisiler.vue'
import Kisi from '../views/Kisi.vue'
import MyIzins from '../views/MyIzins'
import ClientReport from '../views/ClientReport.vue'
import YonetiRapor from '../views/YonetiRapor'
import IncomeReport from '../views/IncomeReport.vue'
import GeneralReport from '../views/GeneralReport.vue'
import MukRapor from '../views/MukRapor.vue'
import KisiGrup from '../views/KisiGrup.vue'
import Karne from '../views/Karne.vue'

import CIBliRapor from '../views/CIBliRapor.vue'

import DevamRapor from '../views/DevamRapor.vue'
import KisiMusteri from '../views/KisiMusteri.vue'
import GroupIncome from '../views/GroupIncome.vue'
import Maas from "../views/Maas.vue"
import CBAnket from '../views/CBAnket.vue'
import AllCallender from '../views/AllCallender.vue'
import Logs from '../views/Logs.vue'
import store from '../store'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    
  },

  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/maas',
    name: 'Maas',
    component: Maas
  },
  {
    path: '/cbanket',
    name: 'CBAnket',
    component: CBAnket
  },
  {
    path: '/yonrap',
    name: 'YonetiRapor',
    component: YonetiRapor
  },
  {
    path: '/clientreport/:id/:datefirst/:datesecond',
    name: 'ClientReport',
    component: ClientReport
  },
  {
    path: '/karne/:datefirst/:datesecond',
    name: 'Karne',
    component: Karne
  },
  {
    path: '/incomereport/:id/:period',
    name: 'IncomeReport',
    component: IncomeReport
  },
  {
    path: '/gruprapor/:id/:period',
    name: 'GroupIncome',
    component: GroupIncome
  },
  {
    path: '/generalreport/:period',
    name: 'GeneralReport',
    component: GeneralReport
  },
  {
    path: '/mukrapor/:period1/:period2',
    name: 'MukRapor',
    component: MukRapor
  },
  {
    path: '/kisigrup/:period1/:period2',
    name: 'KisiGrup',
    component: KisiGrup
  },
  {
    path: '/ciblirapor/:period1/:period2',
    name: 'CIBliRapor',
    component: CIBliRapor
  },
  {
    path: '/kisiler/:datefirst/:datesecond',
    name: 'Kisiler',
    component: Kisiler
  },
  {
    path: '/devamrapor/:datefirst/:datesecond',
    name: 'DevamRapor',
    component: DevamRapor
  },
  {
    path: '/kisimusteri/:id/:datefirst/:datesecond',
    name: 'KisiMusteri',
    component: KisiMusteri
  },
  {
    path: '/kisi/:id/:datefirst/:datesecond',
    name: 'Kisi',
    component: Kisi
  },
  {
    path: '/staffs',
    name: 'Staff',
    component: Staff
  },
  {
    path: '/myizins',
    name: 'MyIzins',
    component: MyIzins
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project
  },
  {
    path: '/new-project',
    name: 'NewProject',
    component: ProjectNew
  },
  {
    path: '/incomes',
    name: 'Income',
    component: Income
  },
  {
    path: '/expenses',
    name: 'Expense',
    component: Expense
  },
  {
    path: '/my-expenses',
    name: 'MyExpense',
    component: MyExpense
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/mytasks',
    name: 'MyTasks',
    component: MyTasks
  },
  {
    path: '/gorevlerim',
    name: 'Gorevlerim',
    component: Gorevlerim
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/raporlar',
    name: 'Raporlar',
    component: Raporlar
  },
  {
    path: '/archived-tasks',
    name: 'ArchivedTasks',
    component: ArchivedTasks
  },
  {
    path: '/delays',
    name: 'AskDelays',
    component: AskDelays
  },
  {
    path: '/delay-admin',
    name: 'AskDelayAdmin',
    component: AskDelayAdmin
  },
  {
    path: '/anket',
    name: 'Questionnaire',
    component: Questionnaire
  },
  {
    path: '/anketd/:str_date',
    name: 'DatedQuestionnaire',
    component: Questionnaire
  },
  {
    path: '/all-task',
    name: 'AllTask',
    component: AllTask
  },
  {
    path: '/all-callender',
    name: 'AllCallender',
    component: AllCallender
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  
  
  if (to.name !=='Login' && !store.state.access_token) next({ name: 'Login' })
  else if(to.name ==='Login' && store.state.access_token) next({name: 'MyTasks'})  
  else if(to.name ==='Home' && store.state.access_token) next({name: 'MyTasks'})


  else next()
})

export default router
