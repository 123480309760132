<template>
  <div class="row">
    <div class="mb-3 col-auto">
  
      <label for="staff" class="form-label">Çalışan</label>
      <select v-model="staff_id" id="staff" class="form-control">
        <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
          {{ staff.username }}
        </option>
      </select>
      <label for="b_donem" class="form-label">Dönem</label>
      <select v-model="b_period" id="b_donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.id"
        >
          {{ p.period }}
        </option>
      </select>
      <label for="e_donem" class="form-label">Dönem</label>
      <select v-model="e_period" id="e_donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.id"
        >
          {{ p.period }}
        </option>
      </select>
      <label for="amount" class="form-label">Tutar</label>
      <input type="number" class="form-control" id="amount" v-model="amount" />
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Güncelle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../services/StaffDataService";
import SalaryService from "../services/SalaryService";
import ClientDataService from "../services/ClientDataService";

import { mapState } from "vuex";

export default {
  name: 'UpdateSalary',
  emits: ["edited"],
  computed: mapState(["task_status", "project_periods", "project_types"]),
  data() {
    return {
      selected: 1,
      staffs:null,
      user_id:null,
      b_period:null,
      e_period:null,
      amount:null
      
    };
  },
  created() {
    StaffDataService.getAll().then(response => {
      this.staffs = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    
      ClientDataService.getAll().then(response => {
        this.projects = response.data
          }).catch(e => {
            console.log(e.response.data);
            })
  },

  
  methods: {
    editClient() {
      let data = {
        user_id:this.staff_id,
        b_period:this.b_period,
        e_period:this.e_period,
        amount:this.amount,
       
      }

      SalaryService.update(data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Güncellendi",
          type: "success"
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>