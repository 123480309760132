<template>
  <div class="row" v-if="task">
    <div class="mb-3 col-auto" >
      <h5>#{{task.id}} No'lu Gorev</h5>
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="task.name"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea class="form-control" id="description" rows="4" v-model="task.description"></textarea>
      
      <label for="end_date" class="form-label">Bitiş</label>
      <input type="date" class="form-control" id="end_date" v-model="task.due_date" />
      <br/>
      <label for="checkbox" class="form-label">Tamamlanma Durumu
        (Görevin Görünür olması için işaretli olması gereklidir) 	&nbsp;</label>
      <input type="checkbox" id="checkbox" class="form-check-input" v-model="task.statusu" /> <br/>
     
      <label for="staff" class="form-label">Görevli</label>
      <select v-model="task.staff_id" id="staff" class="form-control">
        <option
          v-for="staff in staffs"
          :key="staff.id"
          :value="staff.id"
        >
          {{ staff.username }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";
import CallenderDataService from "../../services/CallenderDataService";
import ClientDataService from "../../services/ClientDataService";
// import datePicker from 'vue-bootstrap-datetimepicker';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import { mapState } from "vuex";

export default {
  name: 'CallenderEdit',
  emits: ["edited"],
  props: ["currentTask"],
  computed: mapState(["task_status", "project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      task: this.currentTask,
      staffs: [],
      projects: [],
      options: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
        }
    };
  },
  created() {
    StaffDataService.getAll().then(response => {
      this.staffs = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    ClientDataService.getAll().then(response => {
        this.projects = response.data
        console.log(response.data)
      }).catch(e => {
      console.log(e.response.data);
            })
  },

  watch: {
    currentTask: function (newV, oldV) {
      this.task = newV;
      
      oldV;
    },
  },
  components:{
    // datePicker,
  },
  methods: {
    editClient() {
      let data = {
        name:this.task.name,
        description:this.task.description,
        creation_date:this.task.start_date,
        due_date:this.task.due_date,
      
        staff_id:this.task.staff_id,
        statusu:this.task.statusu
       
      }
      CallenderDataService.update(this.task.id, data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Gorev Düzenlendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>
<style scoped>

</style>