<template>
  <TopNav />
  <SideBar />
   <main class="ml-12 pl-3">
  <div class="row mt-5 pt-5 ms-5">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <h5 class="text-center">{{clientName}} Raporu</h5>
        <h6 class="text-center">{{trh(bas)}} ve {{trh(son)}} tarihleri arası</h6>
      </div>
    </div>
   <div class="row">
    
    <div class="col-md-10"></div>
    <div class="col-md-2"><button class="btn btn-info" @click="toExcel"><i class="bi bi-download"></i> Excele Aktar</button></div>
 </div>

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
    
              
            <tr>
              <th>Görev ID</th>
              <th>Görev</th>
              <th>Çalışan</th>
              <th>Görev Türü</th>
              <th>Başlangıç</th>
              <th>Bitiş</th>
              <th>İş Durumu</th>
              <th>Çalışan Zamanı</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(staff, index) in this.clientItems"
              :key="index" 
            >
            <td>{{staff.task_id}}</td>
            <td>{{staff.task_name}}</td>
            <td>{{staff.staff_name}}</td>
            <td>{{staff.project_type}}</td>            
            <td>{{trh(staff.start_date)}}</td>
            <td>{{trh(staff.end_date)}}</td>
            <td>{{staff.taskstatus}}</td>
            <td>{{binl(staff.sum_hour)}}</td>


              
            </tr>
          </tbody>
        </table>
      </div>
    

   
  
    </div>
  </div>
   </main>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import TableToExcel from "table-to-excel/table-to-excel.js"
import $ from "jquery";
import { mapState } from "vuex";
import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";

export default {    
    name: "ClientReport",
    components: {
      TopNav,
      SideBar},
      computed: mapState(["access_token", "api_url", "client_groups"]),
    mounted() {
      
    this.client_groups.map(x => {if(x.id == this.clientid) this.clientName = x.name})
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"cr/"+this.clientid+"/"+this.bas+"/"+this.son, requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.clientItems = data;
       
        setTimeout(() => {
          $("#datatable").DataTable({
            
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
              
            ],
            dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'pdf', 'print'
        ],
            pageLength: -1,
            
          });
        });
      });
  },
    data(){
        return{
            clientid: this.$route.params.id,
            bas: this.$route.params.datefirst,
            son: this.$route.params.datesecond,
            clientName: null,
            clientItems: []
        }
    },
    methods: {
      toExcel(){
        var tableToExcel=new TableToExcel();
        tableToExcel.render("datatable")
      },
      binl(n) {
       return Number(Number(n).toFixed(2)).toLocaleString('tr-TR')
      },
      trh(myText){
        let my_arr = myText.split("-")
        return my_arr[2]+"."+my_arr[1]+"."+my_arr[0]
      }
    }
}
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>