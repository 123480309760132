<template>
    <div class="card" style="width: 30rem">
      <div class="card-header">#{{ currentTask.id }}|{{ currentTask.name }}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <strong>Oluşturulma:</strong> {{ currentTask.creation_date }}
        </li>
        <li class="list-group-item">
          <strong>Bitiş:</strong> {{ currentTask.due_date }}
        </li>
        
        <li class="list-group-item">
          <strong>Gorevli:</strong> {{ currentTask["staffini.username"] }}
        </li>
        <li class="list-group-item">
          <strong>Yönetici Atamalı:</strong> {{ currentTask["adminin"] }}
        </li>
        <li class="list-group-item">
          <strong>Tamamlanma Durumu:</strong> {{ currentTask["statusu"] }}
        </li>
      </ul>
      <p class="card-text pl-3 ml-3">
        &emsp;<strong>Açıklama:</strong>
        &emsp;{{ currentTask.description }}
      </p>
    </div>
</template>
<script>
export default {
    name: 'CallenderBox',
    props: ["currentTask"],
    
    mounted(){
        this.task = this.currentTask

    }
    
}
</script>