<template>
     <!-- offcanvas -->
    <div
      class="offcanvas offcanvas-start sidebar-nav bg-dark"
      tabindex="-1"
      id="sidebar"
    >
      <div class="offcanvas-body p-0">
        <nav class="navbar-dark">
          <ul class="navbar-nav">
            <!-- <li>
              <div class="text-muted small fw-bold text-uppercase px-3 menu-separator">
                GİRİŞ
              </div>
            </li> -->
            
            <!-- <li>
              <a href="#" class="nav-link px-3 ">
                <span class="me-2"><i class="bi bi-speedometer2"></i></span>
                <span>Genel Görünüm</span>
              </a>
            </li> -->
            <!-- <li class="my-4"><hr class="dropdown-divider bg-light" /></li> -->
            
            <span v-if="is_admin">
              <li >
              <div class="text-muted small fw-bold text-uppercase px-3 mb-3 menu-separator">
                İş YÖNETİMİ 
              </div>
            </li>
            
            
            <li>
              <router-link :to="{ name: 'Clients' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-person-video2"></i></span> 
                <span>Müşteriler</span>
              </router-link>
              
            </li>
            <li>
              <router-link :to="{ name: 'Staff' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-people"></i></span>
                <span>Çalışanlar</span>                
              </router-link>
              
            </li>
            <!--
            <li>
              <router-link :to="{ name: 'Maas' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-people"></i><i class="bi bi-cash-coin"></i></span>
                <span>Maaşlar</span>                
              </router-link>
              
            </li>
            -->
            <li>
              <router-link :to="{ name: 'Income' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-cash-coin"></i></span>
                <span>Gelirler</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Expense' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-cash-stack"></i></span>
                <span>Giderler</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AllTask' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-list-task"></i></span>
                <span>Görevler</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AskDelayAdmin' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-calendar-day"></i></span>
                <span>Erteleme Talepleri</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Raporlar' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-binoculars"></i></span>
                <span>Raporlar</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CBAnket' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-ui-checks"></i></span>
                <span>Yönetici Anketi</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Logs' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-tree"></i></span>
                <span>Loglar</span>                
              </router-link>
            </li>
            <li class="my-4"><hr class="dropdown-divider bg-light" /></li>
            
            </span>


            <span v-if="is_pm">
              <li >
              <div class="text-muted small fw-bold text-uppercase px-3 mb-3 menu-separator">
                İş YÖNETİMİ 
              </div>
            </li>
            
            
            <li>
              <router-link :to="{ name: 'Clients' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-person-video2"></i></span> 
                <span>Müşteriler</span>
              </router-link>
              
            </li>
            <li>
              <router-link :to="{ name: 'Staff' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-people"></i></span>
                <span>Çalışanlar</span>                
              </router-link>
              
            </li>
            
            <li>
              <router-link :to="{ name: 'AllTask' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-list-task"></i></span>
                <span>Görevler</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AskDelayAdmin' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-calendar-day"></i></span>
                <span>Erteleme Talepleri</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Raporlar' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-binoculars"></i></span>
                <span>Raporlar</span>                
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Logs' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-tree"></i></span>
                <span>Loglar</span>                
              </router-link>
            </li>
            <li class="my-4"><hr class="dropdown-divider bg-light" /></li>
            
            </span>
            <li>
              <div class="text-muted small fw-bold text-uppercase px-3 mb-3  menu-separator">
                Kullanıcı Menusü
              </div>
            </li>
            <li>
              <router-link :to="{ name: 'MyTasks' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-list-task"></i></span>
                <span>Aktif Görevlerim</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Questionnaire' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-ui-checks"></i></span>
                <span>Günlük Anket</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AskDelays' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-calendar"></i></span>
                <span>Erteleme Taleplerim</span>
              </router-link>
            </li>
             <li>
              <router-link :to="{ name: 'Tasks' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-megaphone"></i></span>
                <span>Yeni Erteleme Talebi</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ArchivedTasks' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-collection"></i></span>
                <span>Arşivlenmiş Görevler</span>
              </router-link>
            </li>
             <li>
              <router-link :to="{ name: 'Gorevlerim' }" class="nav-link px-3">  
                <span class="me-2"><i class="bi bi-collection"></i></span>
                <span>Oluşturduğum Görevler</span>
              </router-link>
            </li>
           
            <li>
              <router-link :to="{ name: 'MyIzins' }" class="nav-link px-3">  
                <span class="me-2"> <i class="bi bi-tree"></i></span>
                <span>Günlük İzin</span>                
              </router-link>
            </li>
            <div class="dropdown pb-4 zorka">
              
            <hr class="dropdown-divider bg-light" />
            
            <li>
              <div class="nav-link px-3" @click="exitToLogin">
                <span class="me-2"><i class="bi bi-box-arrow-right"></i></span>
                <span>Çıkış</span>
              </div>
              
            </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
    <!-- offcanvas -->
</template>

<script>
import StaffDataService from "../services/StaffDataService";
export default {
  name: 'SideBar',
  data(){return  {

    is_admin: false,
    is_pm: false
    
  }
  },
  props: {
    msg: String
  },
  methods: {
    exitToLogin(){
      this.$store.dispatch('logoutAction')
      this.$router.push('/login')
    }
  },
  created(){
    StaffDataService.whoAmI().then(
      response => {
        console.log(response.data.role)
        if(response.data.role=='Admin'){
          this.is_admin = true
        }
        if(response.data.role=='PM'){
          this.is_pm = true
        }
      }
    )
  }
}
</script>
<style scoped>
.active {
  background-color: cornflowerblue !important;
}

.menu-separator {
text-align: left;
color: cornsilk !important;
}
.zorka {
   position:absolute;
    bottom:0;
}
</style>

