<template>
<SideBar />
<TopNav />
  <main class="ml-2">
    
  <div class="row mt-5 pt-5">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Maaşlar</li>
          </ol>
        </nav>
    <br/>
    <table id="datatable" class="table table-striped table-bordered mt-3 pt-3">
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanici Adi</th>
          <th>Dönemi</th>
          <th>Maaş</th>
          <th>Diger Odemeler</th>
          
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ active: index == currentIndex }"
          v-for="(staff, index) in this.staffs"
          :key="index"
          
        >
          <td>{{ staff.id }}</td>
          <td>{{staff['staff.username']}}</td>
          
          <td>{{staff['periodu.period']}}</td>
          <td>{{staff.amount}}</td>
          <td>{{staff.extra}}</td>
        </tr>
      </tbody>
    </table>
    
    
    <div class="card" v-if="!showIt">
      <span v-if="is_admin">

        <h5 class="card-title">Maaşları Güncelle</h5>
  
        <div class="card-body">
          <UpdateSalary @edited="retrieveStaffs"/>
        </div>
      </span>
    </div>
  </div>
  </main>
</template>
 
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import UpdateSalary from "@/components/UpdateSalary.vue";

import SideBar from "@/components/SideBar.vue"
import TopNav from "@/components/TopNav.vue"
import StaffDataService from "../services/StaffDataService";

export default {
  name: "Maas",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"salaries", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.staffs = data;
        console.log(this.staffs);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  created(){
    StaffDataService.whoAmI().then(
      response => {
        console.log(response.data.role)
        if(response.data.role=='Admin'){
          this.is_admin = true
        }
        if(response.data.role=='PM'){
          this.is_pm = true
        }
      }
    )
  },
  data: function () {
    return {
      staffs: [],
      currentIndex: -1,
      currentStaff: null,
      showIt: false,
      is_admin: false,
      is_pm: false
    };
  },
  components: {
    UpdateSalary,
    
    SideBar,
    TopNav
  },
  methods: {
    clicked(staff, index) {
      console.log(staff.username);
      this.currentStaff = staff;
      this.currentIndex = staff ? index : -1;
      
        this.showIt = true;
      
    },
    retrieveStaffs() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"salaries", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.staffs = data;
          console.log(this.staffs);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>