import http from "../http-common";
import store from '../store'

class CallenderDataService {
    
  getAll() {
    return http.get("/callenders",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getUser(id) {
    return http.get(`/user-callender/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getByDateLeft(id) {
    return http.get(`/callender-erte/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getMines() {
    return http.get("/my-callender",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  get(id) {
    return http.get(`/callender/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
 

  create(data) {
    return http.post("/callender", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/callender/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/callender/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new CallenderDataService();