<template>
  <div class="row">
    <div class="mb-3 col-auto">
      <label for="unvan" class="form-label">Başlık</label>
      <input type="text" class="form-control" id="unvan" v-model="name" />
      <label for="description" class="form-label">Açıklama</label>
      <textarea
        class="form-control"
        id="description"
        rows="4"
        v-model="description"
      ></textarea>
  
      <label for="end_date" class="form-label">Bitiş</label>
      <input
        type="date"
        class="form-control"
        id="end_date"
        v-model="due_date"
      />
     
     
      <span v-if="is_admin">
      <label for="staff" class="form-label">Görevli</label>
      <select v-model="staff_id" id="staff" class="form-control">
        <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
          {{ staff.username }}
        </option>
      </select>
      </span>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";
import CallenderDataService from "../../services/CallenderDataService";
import ClientDataService from "../../services/ClientDataService";

import { mapState } from "vuex";

export default {
  name: 'CallenderNew',
  emits: ["edited"],
  computed: mapState(["task_status", "project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      staffs: [],
      projects: [],
      is_admin: false,
      is_pm: false,
      name:null,
      description:null,
      due_date:null,
     
      staff_id:null
      
      
    };
  },
  created() {
    StaffDataService.getAll().then(response => {
      this.staffs = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
        StaffDataService.whoAmI().then(
      response => {
        console.log(response.data.role)
        if(response.data.role=='Admin'){
          this.is_admin = true
        }
        if(response.data.role=='PM'){
          this.is_pm = true
        }
      }
    )
      ClientDataService.getAll().then(response => {
        this.projects = response.data
          }).catch(e => {
            console.log(e.response.data);
            })
  },

  
  methods: {
    editClient() {
      let data = {
        name:this.name,
        description:this.description,
        
        due_date:this.due_date,
       
       
       
        adminin:this.is_admin
      
          
      }

      CallenderDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Gorev Eklendi",
          type: "success"
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>